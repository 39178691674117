import * as React from "react"

import { Colors } from "@blueprintjs/core"
import styled from "@emotion/styled"

import InstapagePreview from "./InstapagePreview"
import AutoFitPagePreview from "./AutoFitPagePreview"

export { InstapagePreview, AutoFitPagePreview }

const Img = styled.img<
  { height?: string; width?: string; pageHeight?: number } & {
    border?: "green" | "blue"
    shadow?: boolean
    disabled?: boolean
  }
>`
  background-color: white;
  border: 1px solid #738694;
  border-radius: 2px;
  transition: 150ms;
  ${(props) =>
    props.border === "green"
      ? `outline: 2px solid ${Colors.FOREST4};`
      : props.border === "blue"
        ? `outline: 2px solid #4580E6;`
        : null}
  ${(props) =>
    props.shadow && `box-shadow: 0px 2px 8px rgba(92, 112, 128, 0.5);`}
  ${(props) => props.disabled && "opacity: 0.4;"}
`

export const PagePreview = styled(Img)`
  height: ${(props) => props?.height ?? "714px"};
  width: ${(props) => props?.width ?? "552px"};
`

export const ThumbnailPreview = styled(Img)`
  height: ${(props) => props?.height ?? "248px"};
  width: ${(props) => props?.width ?? "192px"};
`

type PageProps = {
  type?: string
  height?: number
  width?: number
  className?: string
}

export default function Page(props: PageProps) {
  return props.type === "instapage" ? (
    <div
      style={{ height: props.height || "auto", width: props.width || "auto" }}
      className={props.className}
    >
      <InstapagePreview {...props} />
    </div>
  ) : (
    <AutoFitPagePreview {...props} />
  )
}

type AutoSizeProps = {
  children: React.ReactNode
}

export const AutoSize = ({ children, ...props }: AutoSizeProps) => {
  let [width, setWidth] = React.useState(null)
  let [height, setHeight] = React.useState(null)
  let container = React.useRef(null)

  React.useLayoutEffect(() => {
    if (container.current) {
      setWidth(container.current.clientWidth)
      setHeight(container.current.clientHeight)
    }
  }, [container.current])

  return (
    <div ref={container} className="h-full w-full">
      {width &&
        React.cloneElement(React.Children.only(children), {
          ...props,
          width: String(width),
          height: String(height),
          pageHeight: height,
        })}
    </div>
  )
}
