import { Document } from "models/Document"
import { Item } from "models/Item"
import { Page, PageV1 } from "models/Pages"
import { PacketSignatoryAssignment } from "models/PacketAssignment"
import { SignatoryAssignment } from "models/SignatoryAssignment"
import { BuiltPage, BuiltPageItem } from "models/BuiltPage"

function buildAssignmentsWithExectuedDocument(
  id: string,
  assignmentsById: Record<string, SignatoryAssignment>,
  documentsById: Record<string, Document>
): PacketSignatoryAssignment | null {
  const assignment = assignmentsById[id]
  if (!assignment) {
    return null // to be filtered out
  }

  const executedSignatureDoc =
    documentsById[assignment.executedDocumentId || ""]

  if (!executedSignatureDoc) {
    return assignment
  }

  return {
    ...assignment,
    executedDocumentStatus: executedSignatureDoc?.status,
    executedDocumentImageUrl:
      executedSignatureDoc?.pageThumbnails[
        (assignment.executedDocumentPage || 0) - 1
      ],
  }
}

const mapDocuments = ({
  page: {
    refId = "",
    refPageNumber = 1,
    assignments: assignmentIds = [],
    id,
    type,
    forSigning,
    isExecutedSignaturePage,
    number,
  },
  assignmentsById,
  ...data
}: PageDataV1): WorkingPageData => ({
  ...data,
  assignmentsById,
  page: {
    id,
    type,
    forSigning,
    isExecutedSignaturePage,
    assignments: assignmentIds
      .map((id) =>
        buildAssignmentsWithExectuedDocument(
          id,
          assignmentsById,
          data.documentsById
        )
      )
      .filter((assignment): assignment is PacketSignatoryAssignment =>
        Boolean(assignment)
      ), // filter out assignments not found
    refId,
    refPageNumber,
    imageUrl:
      data.documentsById[refId]?.pageThumbnails[refPageNumber - 1] || "",
    number,
    items: [],
  },
})

const mapAttachments = (pageData: PageDataV1): WorkingPageData => {
  const {
    page: {
      items: itemIds,
      items: [itemId] = [],
      itemsConfig,
      id,
      label = "",
      name = "",
      frozenPages,
      type,
      number,
      refId,
      refPageNumber,
    },
    ...data
  } = pageData

  let imageUrl = ""
  let items: BuiltPageItem[] = []

  if (itemId) {
    const { itemsById, documentsById } = data

    // If frozen pages exist, we want to target the preview imageUrl from
    // the first page in the frozen attachment pages data
    const firstPageInAttachment = frozenPages?.length
      ? frozenPages[0]
      : itemsById[itemId]?.pages[0]

    imageUrl =
      firstPageInAttachment && firstPageInAttachment.type !== "instapagev2"
        ? documentsById[firstPageInAttachment.refId || ""]?.pageThumbnails[
            (firstPageInAttachment.refPageNumber ?? 0) - 1
          ] || ""
        : ""

    items = (itemIds || [])
      .map((i) => {
        const item = itemsById[i]
        if (!item) return

        return {
          id: item.id,
          pages: (item?.pages || []).map((page) => {
            if (page.type === "attachment") {
              return {
                ...page,
                items:
                  page.items?.map((pageItemId) => itemsById[pageItemId]) || [],
              }
            }
            return page
          }),
          name: item?.name,
        }
      })
      .filter((i): i is BuiltPageItem => Boolean(i))
  }

  return {
    ...data,
    page: {
      id,
      label,
      name,
      type,
      imageUrl,
      items,
      itemsConfig,
      frozenPages,
      number,
      refId,
      refPageNumber,
      forSigning: false,
      assignments: [],
    },
  }
}

const mapInstapages = (pageData: PageDataV1 | PageData): WorkingPageData => {
  const {
    page: { assignments = [], ...page },
    ...data
  } = pageData

  return {
    ...data,
    page: {
      ...page,
      assignments: assignments
        .map((id: string) =>
          buildAssignmentsWithExectuedDocument(
            id,
            data.assignmentsById,
            data.documentsById
          )
        )
        .filter(
          (aOrNull): aOrNull is PacketSignatoryAssignment => aOrNull !== null
        ),
      imageUrl: "",
      items: [],
    },
  }
}

const mapDefaultPage = (pageData: PageDataV1): WorkingPageData => {
  const { page, ...data } = pageData
  return {
    ...data,
    page: {
      ...page,
      assignments: [],
      imageUrl: "",
      items: [],
    },
  }
}

type PageDataV1 = {
  page: PageV1
  itemsById: Record<string, Item>
  documentsById: Record<string, Document>
  assignmentsById: Record<string, SignatoryAssignment>
}

type PageData = {
  page: Page
  itemsById: Record<string, Item>
  documentsById: Record<string, Document>
  assignmentsById: Record<string, SignatoryAssignment>
}

type WorkingPageData = {
  page: BuiltPage
  itemsById: Record<string, Item>
  documentsById: Record<string, Document>
  assignmentsById: Record<string, SignatoryAssignment>
}

export default function buildPage(pageData: PageData): BuiltPage {
  let workingPage
  let page = pageData.page
  switch (page.type) {
    case "document":
      workingPage = mapDocuments({ ...pageData, page }).page
      break
    case "attachment":
      workingPage = mapAttachments({ ...pageData, page }).page
      break
    case "instapage":
      workingPage = mapInstapages({ ...pageData, page }).page
      break
    case "instapagev2":
      workingPage = mapInstapages({ ...pageData, page }).page
      break
    default:
      workingPage = mapDefaultPage({ ...pageData, page }).page
      break
  }

  return workingPage
}
