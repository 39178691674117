import * as React from "react"

import applyOptions from "./apply-options"
import applyPrintScale from "./apply-print-scale"

export default function useImageDimentions(imgUrl: string, options = {}) {
  let imgRef = React.useRef<HTMLImageElement>()
  let [dimensions, setDimensions] = React.useState({
    status: "INIT",
    height: 0,
    width: 0,
    img: null,
    naturalHeight: null,
    naturalWidth: null,
  })
  let imageLocation: Pick<URL, "hostname" | "pathname"> = imgUrl
    ? new URL(imgUrl)
    : {
        hostname: "",
        pathname: "",
      }
  let imageDependency = imageLocation.hostname + imageLocation.pathname

  let onLoad = React.useCallback(
    (evt) => {
      let imgTarget = evt.target
      setDimensions({
        img: imgTarget,
        status: "LOADED",
        height: imgTarget.naturalHeight,
        width: imgTarget.naturalWidth,
        naturalHeight: imgTarget.naturalHeight,
        naturalWidth: imgTarget.naturalWidth,
      })
    },
    [imageDependency]
  )

  return {
    imgRef,
    onLoad,
    dimensions: applyPrintScale(applyOptions(dimensions, options)),
  }
}
