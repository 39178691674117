import { motion } from "framer-motion"

import useImageDimentions from "lib/use-image-dimensions"
import { SyntheticEvent } from "react"

type AutoFitPagePreviewProps = {
  className?: string
  height?: number
  onClick?: () => void
  onLoaded?: () => void
  src?: string
  width?: number
}

export default function AutoFitPagePreview({
  className = "",
  height,
  width,
  src,
  onLoaded = () => null,
  onClick = () => null,
}: AutoFitPagePreviewProps) {
  let { imgRef, onLoad, dimensions } = useImageDimentions(src ?? "", {
    contain: { height, width },
  })

  function handleLoaded(
    evt: SyntheticEvent<HTMLImageElement, Event> | undefined
  ) {
    onLoad(evt)
    onLoaded()
  }

  let imgClasses =
    dimensions.status === "LOADED" ? className || "m-auto block" : ""

  return (
    <div
      className="flex h-full w-full justify-center"
      style={{
        height: dimensions.height || height,
      }}
    >
      <motion.img
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ ease: "easeIn", duration: 0.4 }}
        className={imgClasses}
        onLoad={handleLoaded}
        onClick={onClick}
        ref={imgRef}
        src={src}
        height={dimensions.height}
        width={dimensions.width}
        loading="lazy"
      />
    </div>
  )
}
